import Core from '../../core/core';

export default class CoreCustom extends Core {
  customerId = "{{customerId}}";

  constructor() {
    super();
    window.WIOCONFIG = {
      customerId: this.customerId,
      analyticsEnabled: true,
      sentryEnabled: true,
      renderEnabled: true,
    };
  }
}

window.WIO = new CoreCustom();
