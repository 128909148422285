import { BrowserClient, Hub, Severity } from '@sentry/browser';

export default class Utils {
  static debug = false;

  static generateCiblerId = prefix => `${prefix}.${Math.floor(Math.random() * 1e9)}.${Math.floor((new Date()).getTime() / 1000)}`;


  static message = (message, breadcrumb) => {
    if (this.sentryClient) {
      this.sentryClient.addBreadcrumb({
        category: 'test',
        message: breadcrumb,
        level: Severity.Info,
      });
      this.sentryClient.captureMessage(`wio.js:${message}`);
    }
  };

  static tryFunction = (fn, args, def = null) => {
    try {
      return fn(...args);
    } catch (ex) {
      Utils.captureException(ex);
      return def;
    }
  };

  static parseMonetaryValue = (string) => { // TODO use a library?
    const value = parseFloat(string.replace('€', '').replace(',', '.').trim());
    if (Utils.isNaN(value)) {
      return null;
    }
    return value;
  };

  static hasLocalStorage = () => {
    try {
      const key = '__some_random_key_you_are_not_going_to_use__';
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  };

  static captureException = (err, breadcrumb) => {
    if (this.sentryClient) {
      this.sentryClient.addBreadcrumb(breadcrumb);
      this.sentryClient.captureException(err);
    }
    if (this.debug) {
      console.error(err);
    }
  };

  static getAjax = (url, success, failure, userId) => {
    const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
    xhr.open('GET', url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState > 3) {
        if (xhr.status === 200) return success(xhr.responseText);
        if (failure && failure !== undefined) failure();
      }
      return null;
    };
    xhr.setRequestHeader('CIBLER_ID', userId);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send();
    return xhr;
  };

  static putAjax = (url, success, userId) => {
    const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
    xhr.open('PUT', url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState > 3 && xhr.status === 200) success(xhr.responseText);
    };
    xhr.setRequestHeader('CIBLER_ID', userId);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send();
    return xhr;
  };

  static isNaN = value => window.isNaN(value);

  static getValueWithNaNCheck = (value) => {
    if (Utils.isNaN(value)) {
      return 0;
    }
    return value;
  };

  static HttpPost = (url, success, body, userId) => {
    const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
    xhr.open('POST', url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState > 3 && xhr.status === 200 && success) {
        success(xhr.responseText);
      }
    };
    xhr.setRequestHeader('CIBLER_ID', userId);
    xhr.setRequestHeader('Content-Type', 'application/json');


    xhr.send(JSON.stringify(body));
    return xhr;
  };

  static isStringJson = (str) => {
    let result = null;
    try {
      result = JSON.parse(str);
    } catch (e) {
      return false;
    }
    return !!result;
  };

  static ciblerready = (fn) => {
    if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  };

  static getDevice = () => {
    if (!navigator || !navigator.userAgent) return 'desktop';

    // mode mobile par defaut
    let isMobile = false; // initiate as false
    // device detection
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) isMobile = true;

    return isMobile ? 'mobile' : 'desktop';
  };

  static getQueryString = (field, url) => {
    const href = url || window.location.href;
    const reg = new RegExp(`[?&]${field}=([^&#]*)`, 'i');
    const string = reg.exec(href);
    return string ? string[1] : null;
  };

  static createCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toGMTString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
  };

  static getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  static getCampaignData = (campaignData, defaultValue) => {
    if (!campaignData) {
      if (defaultValue === undefined) return false;
      return defaultValue;
    }
    const data = campaignData.value;
    if (defaultValue === undefined) return data;

    if (!data) return defaultValue;

    if (defaultValue !== null) {
      switch (typeof (defaultValue)) {
        case 'number':
          return parseFloat(data);
        case 'string':
          return data;
        case 'boolean':
          return data === 'true';
        default:
          return data;
      }
    }
    return data;
  };

  static initSentry = (cid) => {
    const client = new BrowserClient({
      dsn: 'https://5e3ee5bbfcad4223a45d929d7579556b@sentry.io/1530451',
      release: VERSION,
      beforeSend(event) {
        if (JSON.stringify(event).match(/wio(render)?.js/)) { // TODO Crado filter
          return event;
        }
      },
    });

    this.sentryClient = new Hub(client);

    this.sentryClient.configureScope((scope) => {
      scope.setTag('customerId', cid);
    });
  };

  static getRandomInt = (Min, Max) => {
    const min = Math.ceil(Min);
    const max = Math.floor(Max);
    return Math.floor(Math.random() * (max - min)) + min;
  }
}
